<template>
  <div>
    <div class="card-group" v-if="page">
      <h2 class="col-12">{{ $t('shop-title') }}</h2>
      <div v-if="!!page.doc.content" class="row mb-5">
        <page-title class="col-12 w-100 d-block" :subheading="page.doc.content"></page-title>
      </div>
      <div class="row w-100">
        <!--<div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3" v-if="new Date() > new Date('2021-05-28T23:00:00.000Z')">
            <div class="card mb-3">
                <div class="card-header card-header-small">
                    The Spring Weekend Seminar
                </div>
                <div class="card-body">
                    <a href="https://www.etno-selo.com/en/" class="text-center">
                        <img src="@/assets/images/2021_autumn_seminar.jpg" alt="autumn weekend seminar" class="card-img internal-video" />
                    </a>


                    <div class="mt-3" >
                        <p v-if="seminarTwentyOneActive">Price until {{ page.ticketPrice.until|formatDateShort }}</p>
                        <p v-else>Ticket is not for sale any longer</p>
                        <br v-if="seminarTwentyOneActive">
                        <span class="bold" v-if="seminarTwentyOneActive">{{page.ticketPrice.price}} € / {{ (page.ticketPrice.price / htlEur).toFixed(2)}} HTL</span>
                    </div>


                    <buy-tickets-modal :price="page.ticketPrice.price" :disabled="!seminarTwentyOneActive" />

                    <div class="mt-3">
                        The amount will be paid from your available HTL. You will get confirmation to your account email. Tickets bought: <span class="bold">{{page.viewer.totals.ticketFallTwentyOne}}</span>.
                    </div>
                </div>
            </div>
        </div>

        <div v-for="item in page.shopItems.filter(x=>x.txtId=='ts22')" :key="item.txtId" class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3">
            <div class="card mb-3">
                <div class="card-header card-header-small">
                    {{item.title}}
                </div>
                <div class="card-body">
                    <a href="https://www.etno-selo.com/lat/" class="text-center">
                        <img src="@/assets/images/lukashenko.jpg" alt="spring seminar" class="card-img internal-video" />
                    </a>

                    <div class="mt-3" >
                        <p v-if="item.currentTicketPricePeriod!=null&&item.currentTicketPricePeriod.to!=null">Price from {{ item.currentTicketPricePeriod.from|formatDateShort }} until {{ item.currentTicketPricePeriod.to|formatDateShort }}</p>
                        <p v-else>Ticket is not for sale any longer</p>
                        <br>
                        <span class="bold">{{item.currentTicketPricePeriod.price}} € / {{ (item.currentTicketPricePeriod.price / htlEur).toFixed(2)}} HTL</span>
                    </div>

                    <buy-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active" :shopItemTxtId="item.txtId"/>

                    <div class="mt-3">
                        The amount will be paid from your available HTL. You will get confirmation to your account email. Tickets bought: <span class="bold">{{userTicketsBought(item)}}</span>.
                    </div>
                </div>
            </div>
        </div>-->
        <!--
        <div v-for="item in page.shopItems.filter(x=>x.txtId=='ts23')" :key="item.txtId"
             class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3">
          <div class="card mb-3">
            <div class="card-header card-header-small">
              {{ item.title }}
            </div>
            <div class="card-body">
              <a class="text-center">
                <img src="@/assets/images/SEMINAR_JUN_2023.jpg" alt="summer seminar" class="card-img internal-video"/>
              </a>

              <div class="mt-3">
                <p v-if="item.currentTicketPricePeriod!=null&&item.currentTicketPricePeriod.to!=null">Price from
                  {{ item.currentTicketPricePeriod.from|formatDateShort }} until
                  {{ item.currentTicketPricePeriod.to|formatDateShort }}</p>
                <p v-else>Ticket is not for sale any longer</p>
                <br>
                <span
                    class="bold">{{ item.currentTicketPricePeriod.price }} € / {{ (item.currentTicketPricePeriod.price / htlEur).toFixed(2) }} HTL</span>
              </div>

              <buy-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                                 :shopItemTxtId="item.txtId"/>

              <div class="mt-3">
                The amount will be paid from your available HTL. You will get confirmation to your account email.
                Tickets bought: <span class="bold">{{ userTicketsBought(item) }}</span>.
              </div>
            </div>
          </div>
        </div>-->


        <div v-for="item in page.shopItems.filter(x => x.txtId == 'ta23')" :key="item.txtId"
          class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3">
          <div class="card mb-3">
            <div class="card-header card-header-small">
              {{ item.title }}
            </div>
            <div class="card-body">
              <a class="text-center">
                <img src="@/assets/images/SEMINAR_AUTUMN_2023.jpg" alt="autumn seminar" class="card-img internal-video" />
              </a>

              <div class="mt-3">
                <p v-if="item.currentTicketPricePeriod != null && item.currentTicketPricePeriod.to != null">Price from
                  {{ item.currentTicketPricePeriod.from | formatDateShort }} until
                  {{ item.currentTicketPricePeriod.to | formatDateShort }}</p>
                <p v-else>Ticket is not for sale any longer</p>
                <br>
                <span class="bold">{{ item.currentTicketPricePeriod.price }} € / {{ (item.currentTicketPricePeriod.price /
                  htlEur).toFixed(2) }} HTL</span>
              </div>
              <table style="width: 100%;">
                <tr>
                  <td style="width: 50%;">
                    <buy-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" />
                  </td>
                  <td style="width: 50%;">
                    <send-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" :ticketsBought="userTicketsBought(item)"
                      :downline="page.viewer.downline" :upline="page.viewer.upline" />
                  </td>
                </tr>
              </table>

              <div class="mt-3">
                The amount will be paid from your available HTL. You will get confirmation to your account email.
                Tickets bought: <span class="bold">{{ userTicketsBought(item) }}</span>.
              </div>
            </div>
          </div>
        </div>
        <div v-for="item in page.shopItems.filter(x => x.txtId == 'tw24')" :key="item.txtId"
          class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3">
          <div class="card mb-3">
            <div class="card-header card-header-small">
              {{ item.title }}
            </div>
            <div class="card-body">
              <a class="text-center">
                <img src="@/assets/images/SEMINAR_WINTER_2024.jpg" alt="winter seminar" class="card-img internal-video" />
              </a>

              <div class="mt-3">
                <p v-if="item.currentTicketPricePeriod != null && item.currentTicketPricePeriod.to != null">Price from
                  {{ item.currentTicketPricePeriod.from | formatDateShort }} until
                  {{ item.currentTicketPricePeriod.to | formatDateShort }}</p>
                <p v-else>Ticket is not for sale any longer</p>
                <br>
                <span class="bold">{{ item.currentTicketPricePeriod.price }} € / {{ (item.currentTicketPricePeriod.price /
                  htlEur).toFixed(2) }} HTL</span>
              </div>
              <table style="width: 100%;">
                <tr>
                  <td style="width: 50%;">
                    <buy-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" />
                  </td>
                  <td style="width: 50%;">
                    <send-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" :ticketsBought="userTicketsBought(item)"
                      :downline="page.viewer.downline" :upline="page.viewer.upline" />
                  </td>
                </tr>
              </table>

              <div class="mt-3">
                The amount will be paid from your available HTL. You will get confirmation to your account email.
                Tickets bought: <span class="bold">{{ userTicketsBought(item) }}</span>.
              </div>
            </div>
          </div>
        </div>
        <div v-for="item in page.shopItems.filter(x => x.txtId == 'ts24')" :key="item.txtId"
          class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3">
          <div class="card mb-3">
            <div class="card-header card-header-small">
              {{ item.title }}
            </div>
            <div class="card-body">
              <a class="text-center">
                <img src="@/assets/images/SEMINAR_SUMMER_2024.jpg" alt="winter seminar" class="card-img internal-video" />
              </a>

              <div class="mt-3">
                <p v-if="item.currentTicketPricePeriod != null && item.currentTicketPricePeriod.to != null">Price from
                  {{ item.currentTicketPricePeriod.from | formatDateShort }} until
                  {{ item.currentTicketPricePeriod.to | formatDateShort }}</p>
                <p v-else>Ticket is not for sale any longer</p>
                <br>
                <span class="bold">{{ item.currentTicketPricePeriod.price }} € / {{ (item.currentTicketPricePeriod.price /
                  htlEur).toFixed(2) }} HTL</span>
              </div>
              <table style="width: 100%;">
                <tr>
                  <td style="width: 50%;">
                    <buy-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" />
                  </td>
                  <td style="width: 50%;">
                    <send-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" :ticketsBought="userTicketsBought(item)"
                      :downline="page.viewer.downline" :upline="page.viewer.upline" />
                  </td>
                </tr>
              </table>

              <div class="mt-3">
                The amount will be paid from your available HTL. You will get confirmation to your account email.
                Tickets bought: <span class="bold">{{ userTicketsBought(item) }}</span>.
              </div>
            </div>
          </div>
        </div>

        <div v-for="item in page.shopItems.filter(x => x.txtId == 'ta24')" :key="item.txtId"
          class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3">
          <div class="card mb-3">
            <div class="card-header card-header-small">
              {{ item.title }}
            </div>
            <div class="card-body">
              <a class="text-center">
                <img src="@/assets/images/SEMINAR_AUTUMN_2024.jpg" alt="autumn seminar" class="card-img internal-video" />
              </a>

              <div class="mt-3">
                <p v-if="item.currentTicketPricePeriod != null && item.currentTicketPricePeriod.to != null">Price from
                  {{ item.currentTicketPricePeriod.from | formatDateShort }} until
                  {{ item.currentTicketPricePeriod.to | formatDateShort }}</p>
                <p v-else>Ticket is not for sale any longer</p>
                <br>
                <span class="bold">{{ item.currentTicketPricePeriod.price }} € / {{ (item.currentTicketPricePeriod.price /
                  htlEur).toFixed(2) }} HTL</span>
              </div>
              <table style="width: 100%;">
                <tr>
                  <td style="width: 50%;">
                    <buy-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" />
                  </td>
                  <td style="width: 50%;">
                    <send-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" :ticketsBought="userTicketsBought(item)"
                      :downline="page.viewer.downline" :upline="page.viewer.upline" />
                  </td>
                </tr>
              </table>

              <div class="mt-3">
                The amount will be paid from your available HTL. You will get confirmation to your account email.
                Tickets bought: <span class="bold">{{ userTicketsBought(item) }}</span>.
              </div>
            </div>
          </div>
        </div>

        <div v-for="item in page.shopItems.filter(x => x.txtId == 'ts25')" :key="item.txtId"
          class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3">
          <div class="card mb-3">
            <div class="card-header card-header-small">
              {{ item.title }}
            </div>
            <div class="card-body">
              <a class="text-center">
                <img src="@/assets/images/SEMINAR_SPRING_2025_Antic.jpg" alt="Spring seminar 2025"
                  class="card-img internal-video" />
              </a>

              <div class="mt-3">
                <p v-if="item.currentTicketPricePeriod != null && item.currentTicketPricePeriod.to != null">Price from
                  {{ item.currentTicketPricePeriod.from | formatDateShort }} until
                  {{ item.currentTicketPricePeriod.to | formatDateShort }}</p>
                <p v-else>Ticket is not for sale any longer</p>
                <br>
                <span class="bold">{{ item.currentTicketPricePeriod.price }} € / {{ (item.currentTicketPricePeriod.price /
                  htlEur).toFixed(2) }} HTL</span>
              </div>
              <table style="width: 100%;">
                <tr>
                  <td style="width: 50%;">
                    <buy-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" />
                  </td>
                  <td style="width: 50%;">
                    <send-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                      :shopItemTxtId="item.txtId" :ticketsBought="userTicketsBought(item)"
                      :downline="page.viewer.downline" :upline="page.viewer.upline" />
                  </td>
                </tr>
              </table>

              <div class="mt-3">
                The amount will be paid from your available HTL. You will get confirmation to your account email.
                Tickets bought: <span class="bold">{{ userTicketsBought(item) }}</span>.
              </div>
            </div>
          </div>
        </div>

        <!-- <div v-for="item in page.shopItems.filter(x=>x.txtId=='tw23')" :key="item.txtId"
             class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-3">
          <div class="card mb-3">
            <div class="card-header card-header-small">
              {{ item.title }}
            </div>
            <div class="card-body">
              <a class="text-center">
                <img src="@/assets/images/seminarZima.jpg" alt="winter seminar" class="card-img internal-video"/>
              </a>

              <div class="mt-3">
                <p v-if="item.currentTicketPricePeriod!=null&&item.currentTicketPricePeriod.to!=null">Price from
                  {{ item.currentTicketPricePeriod.from|formatDateShort }} until
                  {{ item.currentTicketPricePeriod.to|formatDateShort }}</p>
                <p v-else>Ticket is not for sale any longer</p>
                <br>
                <span
                    class="bold">{{ item.currentTicketPricePeriod.price }} € / {{ (item.currentTicketPricePeriod.price / htlEur).toFixed(2) }} HTL</span>
              </div>

              <buy-tickets-modal :price="item.currentTicketPricePeriod.price" :disabled="!item.active"
                                 :shopItemTxtId="item.txtId"/>

              <div class="mt-3">
                The amount will be paid from your available HTL. You will get confirmation to your account email.
                Tickets bought: <span class="bold">{{ userTicketsBought(item) }}</span>.
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import BuyTicketsModal from "@/components/modals/BuyTicketsModal";
import SendTicketsModal from "@/components/modals/SendTicketsModal";
import PageTitle from "@/components/common/PageTitle";

export default {
  name: "MerchantPage",
  components: {
    PageTitle,
    BuyTicketsModal,
    SendTicketsModal
  },
  data() {
    return {
      page: null
    }
  },
  computed: {
    ...mapGetters({
      htlEur: 'prices/htlEur'
    }),
    seminarTwentyOneActive() {
      return new Date(this.page.serverTime.now).getTime() < new Date("2022-03-12T00:00:00").getTime();
    }
  },
  methods: {
    userTicketsBought: function (shopItem) {
      if (shopItem.txtId == "ts22") {
        return this.page.viewer.totals.ticketSpringTwentyTwo;
      } else if (shopItem.txtId == "ta22") {
        return this.page.viewer.totals.ticketAutumnTwentyTwo;
      } else if (shopItem.txtId == "tw23") {
        return this.page.viewer.totals.ticketWinterTwentyThree;
      } else if (shopItem.txtId == "ts23") {
        return this.page.viewer.totals.ticketSummerTwentyThree;
      } else if (shopItem.txtId == "ta23") {
        return this.page.viewer.totals.ticketAutumnTwentyThree;
      } else if (shopItem.txtId == "tw24") {
        return this.page.viewer.totals.ticketWinterTwentyFour;
      } else if (shopItem.txtId == "ts24") {
        return this.page.viewer.totals.ticketSummerTwentyFour;
      } else if (shopItem.txtId == "ta24") {
        return this.page.viewer.totals.ticketAutumnTwentyFour;
      } else if (shopItem.txtId == "ts25") {
        return this.page.viewer.totals.ticketSpringTwentyFive;
      }
    }
  },
  apollo: {
    page: {
      query: gql`query page {
                shopItems {
                    _id,
                    txtId,
                    active,
                    title,
                    content,
                    currentTicketPricePeriod {
                        from,
                        to,
                        price
                    },
                    ticketPricePeriods {
                        from,
                        to,
                        price
                    }
                },
                serverTime {
                    now
                },
                doc(id:"shop") {
                    content,
                    title
                },
                ticketPrice {
                    price,
                    until
                },
                viewer {
                    id,
                    totals {
                        ticketSpringTwentyFive,
                        ticketAutumnTwentyFour,
						            ticketSummerTwentyFour,
                        ticketWinterTwentyFour,
                        ticketAutumnTwentyThree,
						            ticketSummerTwentyThree,
                        ticketWinterTwentyThree,
                        ticketAutumnTwentyTwo,
                        ticketSpringTwentyTwo,
                        ticketSpringTwentyOne,
                        ticketFallTwentyOne,
                        tokens
                    }
                },
                viewerIsAdmin
            }`,
      update: data => data,
      result({ data }) {
        if (data) {
          this.$store.commit('user/updateAvailable', {
            tokens: data.viewer.totals.tokens
          })
          if (data.viewerIsAdmin) {
            this.$store.commit('user/setAdmin')
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.card-m-small {
  height: 2rem;
}

.bold {
  font-weight: 750;
}
</style>